import { AsyncState } from 'state-domains/types';
import {
    Column,
    ExportColumns,
    ExportFilters,
    ExportTemplate,
    HeaderType,
} from 'state-domains/domain/subscription';
import { TableInfo } from 'state-domains/domain/project';
import { FilesToExportActivity } from 'src/components/Data/ExportTemplates/ExportTemplatesMoreDetails/ExportTemplatesMoreDetails.types';

export interface DataState {
    exportTemplatesState: ExportTemplateState;
}

export interface ExportTemplateState extends AsyncState {
    items: ExportTemplate[];
    actionState: AsyncState & { id?: string };
}

export interface DataError {
    error: Error;
}

export enum ExportDestination {
    CSV = 'csv',
    EVO_CSV = 'evo_csv',
    XLSX = 'xlsx',
    EVO = 'evo',
}

export enum ExportEncoding {
    ANSI_LATIN1 = 'cp1252',
    UTF8 = 'utf-8',
}

export enum QuotesMode {
    QUOTE_ALL = 'QUOTE_ALL',
    QUOTE_NONNUMERIC = 'QUOTE_NONNUMERIC',
    QUOTE_MINIMAL = 'QUOTE_MINIMAL',
}

export type TableInfoColumn = { id: string; name: string } & Partial<
    Column & HeaderType & ExportColumns
>;

export interface TableInfoExportTemplates extends TableInfo {
    tablesInTableView?: Record<string, TableInfoExportTemplates>;
    isIncludedInTablesInTableView?: boolean;
    columns?: Record<string, TableInfoColumn>;
    filters?: ExportFilters[];
    isInTemplate?: boolean;
    hasPermission?: boolean;
    activityId?: string;
    tableId?: string;
    duplicates?: TableInfoExportTemplates[];
    isDuplicate?: boolean;
    duplicateFields?: Record<string, string[]>;
    ranking?: Record<string, TableRankedColumnRanking> | null;
    version?: number;
    asSurveyTable?: boolean;
}

export interface TableRankedColumnRanking {
    order: string[];
    type: string;
}

export type CommonTableInfoExportTemplates = TableInfoExportTemplates & {
    activities: Omit<FilesToExportActivity, 'tables'>[];
};
